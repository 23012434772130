@tailwind base;
@tailwind components;
@tailwind utilities;

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: scale(0.5);
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");

div[id^="font-picker"] {
  box-shadow: none !important;
  width: 92% !important;
}

div[id^="font-picker"] .dropdown-button {
  background-color: #fff !important;
  font-size: 12px !important;
  text-transform: capitalize !important;
  height: max-content !important;
  padding: 5px 10px !important;
  border-radius: 20px;
}

div[id^="font-picker"] ul {
  background: #f7f7f7 !important;
}

/* html {
  scroll-behavior: smooth;
} */
