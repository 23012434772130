.default_modal__backgound_trigger {
    background-color: #00000030;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 9999;
    animation: fadeIn 0.2s forwards ease;
  }
  
  .default_modal__container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 80vh;
    min-height: 20vh;
    width: 400px;
    z-index: 9999;
    background-color: #fff;
    border-radius: 10px;
    animation: fadeIn 0.2s forwards ease;
    overflow-x: auto;
  }
  
  .default_modal__header {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    padding: 20px 0px;
  }
  
  .default_modal__header h1 {
    font-size: 20px;
    font-family: var(--cera-bold);
  }
  
  .default_modal__header button {
    background-color: transparent;
    border: 0px;
    cursor: pointer;
    font-size: 16px;
    position: absolute;
    right: 20px;
    top: 20px;
    transition: all 0.2s ease-in;
  }
  
  .default_modal__header button:hover {
    transform: scale(1.2);
  }
  
  .default_modal__content .avatar__input {
    margin: auto;
  }
  
  .default_modal__content {
    padding: 30px;
    font-family: var(--cera-regular);
  }
  
  @media screen and (max-width: 760px) {
    .default_modal__container {
      width: 90%;
    }
  }
  
  .d_block {
    display: block;
  }
  
  .d_none {
    display: none;
  }