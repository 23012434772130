.pwd_check_container li {
    display: flex;
    align-items: center;
    margin: 5px 0px;
    gap: 10px;
  }
  
  .default_pwd_check {
    color: rgba(0, 0, 0, 0.438);
  }
  
  .valid_pwd_check {
    color: green;
  }
  
  .error_pwd_check {
    color: red;
  }
  
  .valid_rounded_pwd_check {
    display: flex;
    padding: 7px;
    background-color: grey;
    border-radius: 100px;
  }
  
  .valid_rounded_pwd_check > i {
    color: white;
    font-size: 8px;
  }
  
  .error_rounded_pwd_check {
    padding: 10px;
    border: 1px solid grey;
    border-radius: 100px;
  }